<template>
    <div class="user-edit-container">
        <nav-bar sizetitle="md"></nav-bar>
        <div class="user-edit__form-container">
            <h1>Editar datos</h1>
            <div class="ue__avatar-container">
                <img :src="userAvatar" alt="avatar">
            </div>
            <div class="ue__data-form-container">
                <div class="ue__data-input">
                    <label for="nombre">Nombres</label>
                    <input type="text" name="nombre" v-model="nombres">
                </div>
                <div class="ue__data-input">
                    <label for="paterno">Apellido paterno</label>
                    <input type="text" name="paterno" v-model="paterno">
                </div>
                <div class="ue__data-input">
                    <label for="materno">Apellido materno</label>
                    <input type="text" name="materno" v-model="materno">
                </div>
                <div class="ue__data-input">
                    <label for="dni">DNI</label>
                    <input type="number" name="dni" v-model="dni">
                </div>
                <div class="ue__data-input">
                    <label for="rol">Rol</label>
                    <select name="rol" v-model="rol">
                        <option value="alumno">Soy alumno</option>
                        <option value="docente">Soy docente</option>
                    </select>
                </div>
                <div class="ue__data-input">
                    <label for="genero">Género</label>
                    <select name="genero" v-model="genero">
                        <option value="masculino">Masculino</option>
                        <option value="femenino">Femenino</option>
                    </select>
                </div>
                <div v-if="rol=='alumno'">
                    <div class="ue__data-input">
                        <label for="nivel">Nivel</label>
                        <select name="nivel" v-model="nivel">
                            <option value="Primaria">Primaria</option>
                            <option value="Secundaria">Secundaria</option>
                        </select>
                    </div>
                    <div class="ue__data-input">
                        <label for="grado">Grado</label>
                        <select name="grado" v-model="grado">
                            <option value="Primero">Primero</option>
                            <option value="Segundo">Segundo</option>
                            <option value="Tercero">Tercero</option>
                            <option value="Cuarto">Cuarto</option>
                            <option value="Quinto">Quinto</option>
                            <option value="Sexto">Sexto</option>
                        </select>
                    </div>
                    <div class="ue__data-input">
                        <label for="seccion">Sección</label>
                        <select name="seccion" v-model="seccion">
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="U">Única</option>
                        </select>
                    </div>
                </div>
                <button class="ue__btn-save" @click="dataValidate()">Guardar datos</button>
            </div>
        </div>
    </div>
</template>
<script>
import NavBar from '../components/NavBar'
import {auth,db} from '../firebase'
export default {
    components:{
        NavBar
    },
    data:()=>({
        userAvatar:'',
        nombres:'',
        paterno:'',
        materno:'',
        dni:'',
        rol:'',
        genero:'',
        nivel:'',
        grado:'',
        seccion:'',
        avatar1:'https://res.cloudinary.com/juegos-cramer/image/upload/v1616176603/juegos-cramer/man_icon-icons.com_55040_a2fdk5.svg',
        avatar2:'https://res.cloudinary.com/juegos-cramer/image/upload/v1616176603/juegos-cramer/girl_icon-icons.com_55043_ot2ktw.svg',
        userId:null
    }),
    methods:{
        getCurrentUser(){
            return new Promise((resolve,reject)=>{
                const unsubscribe = auth.onAuthStateChanged(
                user =>{
                    unsubscribe();
                    resolve(user);
                },
                ()=>{
                    reject();
                }
                );
            });
        },
        getUserImage(id){
            let docRef = db.collection("usuarios").doc(id);
            docRef.get().then((doc) => {
                if (doc.exists) {
                    this.userAvatar = doc.data().avatar;
                    this.nombres = doc.data().nombres;
                    this.paterno = doc.data().paterno;
                    this.materno = doc.data().materno;
                    this.dni=doc.data().dni;
                    this.rol=doc.data().rol;
                    this.genero=doc.data().genero;
                    this.nivel = doc.data().nivel;
                    this.grado = doc.data().grado;
                    this.seccion = doc.data().seccion;
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        },
        dataValidate(){
            if(this.rol=="alumno")
            {
                if( this.nombres&&
                    this.paterno&&
                    this.materno&&
                    this.dni&&
                    this.rol&&
                    this.genero&&
                    this.nivel&&
                    this.grado&&
                    this.seccion)
                    {
                        this.saveUserData()
                    }
                else{
                    alert("complete todos los campos")
                }
            }else{
                if( this.nombres&&
                    this.paterno&&
                    this.materno&&
                    this.dni&&
                    this.rol&&
                    this.genero)
                    {
                        this.saveUserData()
                    }
                else{
                    alert("complete todos los campos")
                }
            }
        },
        saveUserData(){
            if(this.rol=='alumno')
            {
                db.collection("usuarios").doc(this.userId).set({
                    nombres:this.nombres,
                    paterno:this.paterno,
                    materno:this.materno,
                    dni:this.dni,
                    rol:this.rol,
                    genero:this.genero,
                    nivel:this.nivel,
                    grado:this.grado,
                    seccion:this.seccion,
                    avatar:(this.genero=='masculino')?this.avatar1:this.avatar2
                })
                .then(() => {
                    alert("Datos guardados con éxito")
                    this.$router.push('/perfil-usuario')
                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                });
            }else{
                db.collection("usuarios").doc(this.userId).set({
                    nombres:this.nombres,
                    paterno:this.paterno,
                    materno:this.materno,
                    dni:this.dni,
                    rol:this.rol,
                    genero:this.genero,
                    avatar:(this.genero=='masculino')?this.avatar1:this.avatar2
                })
                .then(() => {
                    alert("Datos guardados con éxito")
                    this.$router.push('/perfil-usuario')
                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                });
            }
        }
    },
    async mounted(){
        let user = await this.getCurrentUser()
        this.userId=user.uid;
        this.getUserImage(user.uid)
    }
}
</script>
<style>
.user-edit-container{
    margin:0px;
    width: 100%;
    min-height: 100vh;
    padding-bottom:10em;
    background-color: #1F0275;
}
.user-edit__form-container{
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.user-edit__form-container h1{
    width: 100%;
    padding-top:1em;
    text-align: center;
    border-top:solid 1px #F23827;
}
.ue__avatar-container{
    width: 7em;
    height: 7em;
    border-radius: 50%;
    overflow: hidden;
    border: solid 3px #F23827;
}
.ue__avatar-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.ue__data-form-container{
    margin-top: 1.5em;
    width: 80%;
}
.ue__data-input{
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
}
.ue__data-input label{
    font-size: 1.1em;
    margin-bottom: 2px;
}
.ue__data-input input,.ue__data-input select{
    font-size: 1em;
    padding: 0.3em;
}
.ue__btn-save {
    width: 100%;
    font-size: 1.2em;
    padding: 0.5em;
    background-color: teal;
    color: white;
    border:0px;
}
.ue__data-input input:focus,.ue__data-input select:focus,.ue__btn-save:focus{
    border:0px;
}
input:focus, input.form-control:focus,button {

    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
</style>